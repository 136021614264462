import { routeNames, TRoutes } from '~/routes/const';
import { EAuthCode } from '~/enums/EAuthCode';

export default [
  {
    path: routeNames.writeOffGroup,
    component: () => import('../../pages/writeOffManagement/writeOffGroup/list'),
    meta: {
      title: '核销组管理',
       code: EAuthCode.WRITEOFF_GROUP
    }
  },
  {
    path: routeNames.writeOffGroupAdd,
    component: () => import('../../pages/writeOffManagement/writeOffGroup/add'),
    meta: {
      title: '核销组详情',
      hideInMenu: true,
      code: [EAuthCode.WRITEOFF_GROUP_ADD,EAuthCode.WRITEOFF_GROUP_UPDATE ]
    }
  },
  {
    path: routeNames.whiteList,
    component: () => import('../../pages/writeOffManagement/whiteList'),
    meta: {
      title: '核销员工白名单',
      code: EAuthCode.WRITEOFF_WHITELIST
    }
  }
] as TRoutes[]