import { routeNames, TRoutes } from '~/routes/const'
import { EAuthCode } from '~/enums/EAuthCode'

export default [
  {
    path: routeNames.goodsManagementList,
    component: () => import('../../pages/goodsManagement/goodsList'),
    meta: {
      title: '商品管理',
      code: EAuthCode.GOOD_GOODSMANAGEMENT
    }
  },
  {
    path: routeNames.goodsManagementGroup,
    component: () => import('../../pages/goodsManagement/goodsGroup'),
    meta: {
      title: '商品组',
      code: EAuthCode.GOOD_GOODSGROUP
    }
  },
  {
    path: routeNames.goodsManagementChildGroup,
    component: () => import('../../pages/goodsManagement/goodsChildGroup'),
    meta: {
      title: '商品子组',
      code: EAuthCode.GOOD_GOODSCHILDGROUP
    }
  },
  {
    path: routeNames.goodsManagementTags,
    component: () => import('../../pages/goodsManagement/goodsTags'),
    meta: {
      title: '标签管理',
      code: EAuthCode.GOOD_TAGS
    }
  },
  {
    path: routeNames.goodsManagementPoster,
    component: () => import('../../pages/goodsManagement/goodsPoster'),
    meta: {
      title: '海报管理',
      code: EAuthCode.GOOD_POSTER
    }
  },
  {
    path: routeNames.goodsManagementComments,
    component: () => import('../../pages/goodsManagement/goodsComments'),
    meta: {
      title: '评价管理',
      code: EAuthCode.GOOD_COMMENTS
    }
  },
  {
    path: routeNames.goodsManagementCode,
    component: () => import('../../pages/goodsManagement/goodsCode'),
    meta: {
      title: '核销码管理',
      hideInMenu: true,
      code: EAuthCode.GOOD_GOODSMANAGEMENT_VIRTUALCODE
    }
  },
  {
    path: routeNames.goodsManagementCard,
    component: () => import('../../pages/goodsManagement/goodsCard'),
    meta: {
      title: '卡密管理',
      hideInMenu: true,
      code: EAuthCode.GOOD_GOODSMANAGEMENT_VIRTUALCARD
    }
  },
  {
    path: routeNames.goodsManagementStock,
    component: () => import('../../pages/goodsManagement/goodsStock'),
    meta: {
      title: '库存管理',
      hideInMenu: true,
      code: EAuthCode.GOOD_GOODSMANAGEMENT_STOCKMANAGE
    }
  },
  {
    path: routeNames.goodsManagementCreate,
    component: () => import('../../pages/goodsManagement/goodsCreate'),
    meta: {
      title: '商品编辑',
      hideInMenu: true,
      code: [EAuthCode.GOOD_GOODSMANAGEMENT_ADD, EAuthCode.GOOD_GOODSMANAGEMENT_ADDVIRTUAL, EAuthCode.GOOD_GOODSMANAGEMENT_UPDATE]
    }
  },
  {
    path: routeNames.goodsManagementDetail,
    component: () => import('../../pages/goodsManagement/goodsDetail'),
    meta: {
      title: '商品详情',
      hideInMenu: true
      // code: EAuthCode.GOOD_GOODSMANAGEMENT_READONLY
    }
  }
] as TRoutes[]
