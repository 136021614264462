import { notification, Typography } from 'antd'
import { EShopForbiddenStatus, EShopStoreStatus } from '~/enums/EShopAduitStatus'
import { MallStoreAdminGroupVo } from '~/request/data-contracts'
import { routeNames } from './const'

/**
 * 进入商品列表页面检查
 */
export function checkGoodsListAuth(pathname?: string, storeInfo?: MallStoreAdminGroupVo): boolean {
  if (pathname === routeNames.goodsManagementList) {
    if (storeInfo?.storeStatus === EShopStoreStatus.Expire) {
      notification.warning({
        message: '禁止访问',
        duration: 4,
        description: (
          <div>
            店铺已过期，请重新提交审核。如已经审核通过，请 <Typography.Link onClick={() => window.location.reload()}>刷新页面</Typography.Link> 后再试试。
          </div>
        )
      })
      return false
    } else if (storeInfo?.forbid === EShopForbiddenStatus.Disabled) {
      notification.warning({
        message: '禁止访问',
        duration: 4,
        description: (
          <div>
            店铺已被禁用，请联系客服解封。如已经解封，请 <Typography.Link onClick={() => window.location.reload()}>刷新页面</Typography.Link> 后再试试。
          </div>
        )
      })
      return false
    }
  }
  return true
}
