import { useContext } from "react";
import { skipAuth } from "~/config";
import globalContext from "~/contexts/global.context";
import { EAuthCode } from "~/enums/EAuthCode";


// interface IUserAuth<T extends EAuthCode = EAuthCode> {
// codes: T[]
// }

// type TUseAuth<T extends EAuthCode = EAuthCode> = Record<T, any>
// type ValueOf<T> = T[keyof T];


/**
 * 获取是否具有权限
 *
 * @export
 * @param {EAuthCode[]} codes
 * @return {*} 
 */
export default function useAuth<T extends keyof typeof EAuthCode>(codes?: T[]) {
  const { authCodes: auths } = useContext(globalContext)

  /** 判断是否有权限 */
  function vaildAuth(code: string) {
    return skipAuth || auths.indexOf(code) !== -1
  }

  const authMap = (codes || []).reduce((obj, code) => {
    obj[code] = vaildAuth(EAuthCode[code])
    return obj
  }, {} as { [P in T]: boolean })


  return {
    vaildAuth,
    authMap
  }
}