import { FC, memo, useState } from 'react'
import { Button, Form, Input, message, Statistic } from 'antd'
import styles from './index.module.less'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { useForm } from 'antd/lib/form/Form'
import { useHistory } from 'react-router-dom'
import { api } from '~/request'
import { routeNames } from '~/routes/const'
import { ECodeScene } from '~/enums/ECodeScene'
import { isMobilePhone } from '~/modules/@wmeimob/backend-pro/src/utils/validator'
import { IRegisterFormProps } from './const'
import { encryptPassword } from '../../const'

const { Item } = Form
const { Countdown } = Statistic

const Component: FC<IRegisterFormProps> = (props) => {
  const { scene = ECodeScene.REG } = props
  const history = useHistory()
  const [form] = useForm()
  const [passwordType, setPasswordType] = useState('password')
  const [showCountDown, setShowCountDown] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)

  async function handleFinish() {
    const result = await form.validateFields()
    const { mobile, password, code } = result
    const encrypted = encryptPassword(password)

    const updateData = { mobile, password: encrypted, code }
    setButtonLoading(true)
    if (scene === ECodeScene.REG) {
      api['/admin/auth/register_POST'](updateData)
        .then(({ data }) => {
          window.localStorage.setItem('Authorization', 'Bearer ' + (data || ''))
          history.replace(routeNames.home)
        })
        .finally(() => {
          setButtonLoading(false)
        })
    } else {
      api['/admin/auth/forgot_POST'](updateData)
        .then(() => {
          message.success('密码修改成功')
          setTimeout(() => {
            props.onLoginClick()
          }, 1000)
        })
        .finally(() => {
          setButtonLoading(false)
        })
    }
  }

  const handleCodeClick = async () => {
    const value = await form.validateFields(['mobile'])
    try {
      setShowCountDown(true)
      await api['/admin/sms/verifyCode_GET']({ ...value, scene })
      message.success('验证码已经发送')
    } catch (error) {
      message.error('验证码发送失败')
      setShowCountDown(false)
    }
  }

  return (
    <div className={styles.registerFormStyle}>
      <h2>{scene === ECodeScene.FORGOT ? '忘记密码' : '注册'}</h2>
      <Form labelCol={{ span: 6 }} labelAlign="left" form={form} onFinish={handleFinish}>
        <Item
          label="手机号码"
          name="mobile"
          validateFirst
          rules={[
            { required: true, message: '请输入手机号码' },
            { validator: (_, value) => (isMobilePhone(value) ? Promise.resolve() : Promise.reject(new Error('请输入正确的手机号码'))) }
          ]}
        >
          <Input placeholder="请输入手机号码" size="large" />
        </Item>

        <Item label="短信验证" name="code" rules={[{ required: true, message: '请输入短信验证码' }]}>
          <Input
            placeholder="请输入短信验证码"
            size="large"
            suffix={
              showCountDown ? (
                <Countdown value={Date.now() + 60000} valueStyle={{ fontSize: 14, color: '#cccccc' }} format="ss" onFinish={() => setShowCountDown(false)} />
              ) : (
                <Button type="link" onClick={handleCodeClick}>
                  获取验证码
                </Button>
              )
            }
          />
        </Item>

        <Item
          label={scene === ECodeScene.REG ? '登录密码' : '新密码'}
          name="password"
          validateFirst
          rules={[
            { required: true, message: '请输入密码' },
            {
              required: true,
              validator: (_, value = '') => {
                console.log(value)
                if (value.length >= 9 && /^(?=.*[0-9])(?=.*[a-z])(?=.*["_+=!@#$%&*--"])(?=.*[A-Z]).{9,20}$/.test(value)) {
                  return Promise.resolve()
                }
                return Promise.reject('9~20位密码，须包含大小写，特殊字符')
              }
            }
          ]}
        >
          <Input
            placeholder="请输入密码"
            size="large"
            type={passwordType}
            maxLength={20}
            suffix={
              passwordType === 'password' ? (
                <EyeInvisibleOutlined onClick={() => setPasswordType('text')} />
              ) : (
                <EyeOutlined onClick={() => setPasswordType('password')} />
              )
            }
          />
        </Item>

        <Item>
          <Button type="primary" block size="large" htmlType="submit" loading={buttonLoading}>
            {scene === ECodeScene.REG ? '注册' : '确定'}
          </Button>
        </Item>

        <Item>
          <div style={{ textAlign: 'center' }}>
            <Button type="link" onClick={props.onLoginClick}>
              已有账户,去登录
            </Button>
          </div>
        </Item>
      </Form>
    </div>
  )
}

Component.displayName = 'RegisterForm'

const RegisterForm = memo(Component)
export default RegisterForm
