import { routeNames, TRoutes } from '~/routes/const'
import { EAuthCode } from '~/enums/EAuthCode'

export default [
  {
    path: routeNames.orderManagementList,
    component: () => import('../../pages/orderManagement/orderList'),
    meta: {
      title: '订单列表',
      code: EAuthCode.ORDER_LIST
    }
  },
  {
    path: routeNames.orderManagementVirtualList,
    component: () => import('../../pages/orderManagement/orderList/virtual'),
    meta: {
      title: '虚拟商品订单',
      code: EAuthCode.ORDER_VIRTUALLIST
    }
  },
  {
    path: routeNames.orderManagementShipping,
    component: () => import('../../pages/orderManagement/orderShipping'),
    meta: {
      title: '发货管理',
      code: EAuthCode.ORDER_SHIPPING
    }
  },
  {
    path: routeNames.orderManagementAftersale,
    component: () => import('../../pages/orderManagement/orderAftersale'),
    meta: {
      title: '售后管理',
      code: EAuthCode.ORDER_AFTERSALE
    }
  },
  {
    path: routeNames.orderManagementArbitration,
    component: () => import('../../pages/orderManagement/orderArbitration'),
    meta: {
      title: '仲裁列表',
      code: EAuthCode.ORDER_ARBITRATION
    }
  },
  {
    path: routeNames.orderManagementBatchShipping,
    component: () => import('../../pages/orderManagement/batchShipping'),
    meta: {
      title: '批量发货',
      hideInMenu: true,
      code: EAuthCode.ORDER_SHIPPING_BATCHSHIPPING
    }
  },
  {
    path: routeNames.orderManagementDetail,
    component: () => import('../../pages/orderManagement/orderDetail'),
    meta: {
      title: '订单详情',
      hideInMenu: true,
      code: [
        EAuthCode.ORDER_LIST_DETAIL,
        EAuthCode.FINANCE_SHOPACCOUNT_ORDERDETAIL,
        EAuthCode.FINANCE_SHOPMARKETINGACCOUNT_ORDERDETAIL,
        EAuthCode.FINANCE_SHOPDEPOSITACCOUNT_ORDERDETAIL,
        EAuthCode.FINANCE_RECEIVABLEORDER_ORDERDETAIL,
        EAuthCode.FINANCE_RECEIVEDORDER_ORDERDETAIL,
        EAuthCode.FINANCE_REFUNDORDER_ORDERDETAIL,
        EAuthCode.FINANCE_PLATFORMSUBSIDY_ORDERDETAIL,
        EAuthCode.ORDER_AFTERSALE_ORDERDETAIL,
        EAuthCode.ORDER_ARBITRATION_ORDERDETAIL,
        EAuthCode.FINANCE_INVOICEMANAGEMENT_DETAIL
      ]
    }
  },
  {
    path: routeNames.orderManagementAfterDetail,
    component: () => import('../../pages/orderManagement/afterDetail'),
    meta: {
      title: '售后详情',
      hideInMenu: true,
      code: [
        EAuthCode.ORDER_AFTERSALE_AFTERSALEDETAIL,
        EAuthCode.ORDER_ARBITRATION_AFTERSALEDETAIL,
        EAuthCode.FINANCE_SHOPACCOUNT_AFTERSALEDETAIL,
        EAuthCode.FINANCE_REFUNDORDER_AFTERSALEDETAIL
      ]
    }
  },
  {
    path: routeNames.orderManagementArbitrationDetail,
    component: () => import('../../pages/orderManagement/arbitrationDetail'),
    meta: {
      title: '仲裁详情',
      hideInMenu: true,
      code: [EAuthCode.ORDER_ARBITRATION_ARBITRATIONDETAIL, EAuthCode.FINANCE_REFUNDORDER_ARBITRATIONDETAIL]
    }
  },
  {
    path: routeNames.orderManagementDeliveryPrint,
    component: () => import('../../pages/orderManagement/deliveryPrint'),
    meta: {
      title: '批量打印配送单',
      hideInMenu: true,
      code: EAuthCode.ORDER_SHIPPING_BATCHPRINTSHIPPINGRECEIPTS
    }
  },
  {
    path: routeNames.orderManagementExpressPrint,
    component: () => import('../../pages/orderManagement/expressPrint'),
    meta: {
      title: '批量打印商品清单',
      hideInMenu: true,
      code: EAuthCode.ORDER_SHIPPING_BATCHPRINTEXPRESSRECEIPTS
    }
  },
  {
    path: routeNames.orderManagementExpressUpload,
    component: () => import('../../pages/orderManagement/expressUpload'),
    meta: {
      title: '导入发货',
      hideInMenu: true,
      code: EAuthCode.ORDER_SHIPPING_IMPORT
    }
  }
] as TRoutes[]
