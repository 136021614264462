import { routeNames, TRoutes } from '~/routes/const';
import { EAuthCode } from '~/enums/EAuthCode';

export default [
  {
    path: routeNames.couponTemplate,
    component: () => import('../../pages/couponManagement/couponTemplate'),
    meta: { title: '优惠券模板管理' , code: EAuthCode.COUPONMANAGEMENT_TEMPLATE }
  },
  {
    path: routeNames.couponTemplateAdd,
    component: () => import('../../pages/couponManagement/couponTemplate/add'),
    meta: { title: '优惠券模板', hideInMenu: true, code: EAuthCode.COUPONMANAGEMENT_TEMPLATE_ADD }
  },
  {
    path: routeNames.couponTemplateCheck,
    component: () => import('../../pages/couponManagement/couponTemplate/check'),
    meta: { title: '优惠券模板详情', hideInMenu: true , code: EAuthCode.COUPONMANAGEMENT_TEMPLATE_DETAIL}
  },
  {
    path: routeNames.couponTemplateCodeLibrary,
    component: () => import('../../pages/couponManagement/couponTemplate/codeLibrary'),
    meta: { title: '优惠券领取记录', hideInMenu: true , code: EAuthCode.COUPONMANAGEMENT_TEMPLATE_RECORD}
  }

] as TRoutes[]