import { routeNames, TRoutes } from '~/routes/const'
import { EAuthCode } from '~/enums/EAuthCode'

export default [
  {
    path: routeNames.notifyList,
    component: () => import('../../pages/notifyList'),
    meta: { title: '消息通知列表', hideInMenu: true }
  }
] as TRoutes[]
