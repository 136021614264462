export enum EAuthCode {
  /** 商家管理 */
  BUSINESS = 'business',
  /** 商家管理-店铺状态 */
  BUSINESS_SHOPSTATE = 'business-shopState',
  /** 商家管理-店铺状态-预览 */
  BUSINESS_SHOPSTATE_QUERY = 'business-shopState-query',
  /** 商家管理-店铺状态-关店 */
  BUSINESS_SHOPSTATE_CLOSESHOP = 'business-shopState-closeShop',
  /** 商家管理-店铺状态-再次审核 */
  BUSINESS_SHOPSTATE_REAUDIT = 'business-shopState-reAudit',
  /** 商家管理-店铺状态-切换运营 */
  BUSINESS_SHOPSTATE_SWITCHSTATE = 'business-shopState-switchState',
  /** 商家管理-店铺状态-提现 */
  BUSINESS_SHOPSTATE_WITHDRAW = 'business-shopState-withdraw',
  /** 商家管理-店铺状态-充值 */
  BUSINESS_SHOPSTATE_RECHARGE = 'business-shopState-recharge',

  /** 商家管理-店铺信息 */
  BUSINESS_SHOPMESSAGE = 'business-shopMessage',
  /** 商家管理-店铺信息-预览 */
  BUSINESS_SHOPMESSAGE_QUERY = 'business-shopMessage-query',
  /** 商家管理-店铺信息-编辑 */
  BUSINESS_SHOPMESSAGE_UPDATE = 'business-shopMessage-update',

  /** 商家管理-企业信息 */
  BUSINESS_ENTERPRISEINFORMATION = 'business-enterpriseInformation',
  /** 商家管理-企业信息-预览 */
  BUSINESS_ENTERPRISEINFORMATION_QUERY = 'business-enterpriseInformation-query',
  /** 商家管理-企业信息-编辑 */
  BUSINESS_ENTERPRISEINFORMATION_UPDATE = 'business-enterpriseInformation-update',

  /** 商家管理-经营类目 */
  BUSINESS_CLASSIFY = 'business-classify',
  /** 商家管理-经营类目-预览 */
  BUSINESS_CLASSIFY_QUERY = 'business-classify-query',

  /** 商家管理-品牌管理 */
  BUSINESS_BRANDMANAGEMENT = 'business-brandManagement',
  /** 商家管理-品牌管理-预览 */
  BUSINESS_BRANDMANAGEMENT_QUERY = 'business-brandManagement-query',
  /** 商家管理-品牌管理-添加品牌 */
  BUSINESS_BRANDMANAGEMENT_ADD = 'business-brandManagement-add',
  /** 商家管理-品牌管理-编辑 */
  BUSINESS_BRANDMANAGEMENT_UPDATE = 'business-brandManagement-update',
  /** 商家管理-品牌管理-删除 */
  BUSINESS_BRANDMANAGEMENT_DELETE = 'business-brandManagement-delete',
  /** 商家管理-品牌管理-品牌认领 */
  BUSINESS_BRANDMANAGEMENT_BRANDCLAIM = 'business-brandManagement-brandClaim',

  /** 商家管理-员工管理 */
  BUSINESS_STAFF = 'business-staff',
  /** 商家管理-员工管理-预览 */
  BUSINESS_STAFF_QUERY = 'business-staff-query',
  /** 商家管理-员工管理-新增 */
  BUSINESS_STAFF_ADD = 'business-staff-add',
  /** 商家管理-员工管理-编辑 */
  BUSINESS_STAFF_UPDATE = 'business-staff-update',
  /** 商家管理-员工管理-删除 */
  BUSINESS_STAFF_DELETE = 'business-staff-delete',
  /** 商家管理-员工管理-重置密码 */
  BUSINESS_STAFF_RESETPWD = 'business-staff-resetPWD',
  /** 商家管理-员工管理-开通客服 */
  BUSINESS_STAFF_SERVICER = 'business-staff-servicer',

  /** 商家管理-部门管理 */
  BUSINESS_DEPT = 'business-dept',
  /** 商家管理-部门管理-预览 */
  BUSINESS_DEPT_QUERY = 'business-dept-query',
  /** 商家管理-部门管理-新增 */
  BUSINESS_DEPT_ADD = 'business-dept-add',
  /** 商家管理-部门管理-编辑 */
  BUSINESS_DEPT_UPDATE = 'business-dept-update',
  /** 商家管理-部门管理-删除 */
  BUSINESS_DEPT_DELETE = 'business-dept-delete',

  /** 商家管理-角色管理 */
  BUSINESS_ROLE = 'business-role',
  /** 商家管理-角色管理-预览 */
  BUSINESS_ROLE_QUERY = 'business-role-query',
  /** 商家管理-角色管理-新增 */
  BUSINESS_ROLE_ADD = 'business-role-add',
  /** 商家管理-角色管理-编辑 */
  BUSINESS_ROLE_UPDATE = 'business-role-update',
  /** 商家管理-角色管理-删除 */
  BUSINESS_ROLE_DELETE = 'business-role-delete',

  /** 商家管理-操作日志记录 */
  BUSINESS_LOGRECORD = 'business-logRecord',
  /** 商家管理-操作日志记录-预览 */
  BUSINESS_LOGRECORD_QUERY = 'business-logRecord-query',

  /** 商品管理 */
  GOOD = 'good',
  /** 商品管理-商品管理 */
  GOOD_GOODSMANAGEMENT = 'good-goodsManagement',
  /** 商品管理-商品管理-预览 */
  GOOD_GOODSMANAGEMENT_QUERY = 'good-goodsManagement-query',
  /** 商品管理-商品管理-导出 */
  GOOD_GOODSMANAGEMENT_EXPORT = 'good-goodsManagement-export',
  /** 商品管理-商品管理-添加商品 */
  GOOD_GOODSMANAGEMENT_ADD = 'good-goodsManagement-add',
  /** 商品管理-商品管理-添加虚拟商品 */
  GOOD_GOODSMANAGEMENT_ADDVIRTUAL = 'good-goodsManagement-addVirtual',
  /** 商品管理-商品管理-编辑 */
  GOOD_GOODSMANAGEMENT_UPDATE = 'good-goodsManagement-update',
  /** 商品管理-商品管理-重新审核 */
  GOOD_GOODSMANAGEMENT_REUPDATE = 'good-goodsManagement-reUpdate',
  /** 商品管理-商品管理-修改销售属性 */
  GOOD_GOODSMANAGEMENT_UPDATEATTRIBUTE = 'good-goodsManagement-updateAttribute',
  /** 商品管理-商品管理-显示状态 */
  GOOD_GOODSMANAGEMENT_SHOWSTATUS = 'good-goodsManagement-showStatus',
  /** 商品管理-商品管理-金龙卡显示状态 */
  GOOD_GOODSMANAGEMENT_SHOWCARD = 'good-goodsManagement-showCard',
  /** 商品管理-商品管理-下架 */
  GOOD_GOODSMANAGEMENT_DOWN = 'good-goodsManagement-down',
  /** 商品管理-商品管理-上架 */
  GOOD_GOODSMANAGEMENT_UP = 'good-goodsManagement-up',
  /** 商品管理-商品管理-删除 */
  GOOD_GOODSMANAGEMENT_DELETE = 'good-goodsManagement-delete',
  /** 商品管理-商品管理-查看 */
  GOOD_GOODSMANAGEMENT_READONLY = 'good-goodsManagement-readonly',
  /** 商品管理-商品管理-库存管理 */
  GOOD_GOODSMANAGEMENT_STOCKMANAGE = 'good-goodsManagement-stockManage',
  /** 商品管理-商品管理-库存编辑 */
  GOOD_GOODSMANAGEMENT_STOCKMANAGEEDIT = 'good-goodsManagement-stockManageEdit',
  /** 商品管理-商品管理-库存管理明细查询 */
  GOOD_GOODSMANAGEMENT_STOCKMANAGEDETAIL = 'good-goodsManagement-stockManageDetail',
  /** 商品管理-商品管理-库存管理明细导出 */
  GOOD_GOODSMANAGEMENT_STOCKMANAGEDETAILEXPORT = 'good-goodsManagement-stockManageDetailExport',
  /** 商品管理-商品管理-核销码管理 */
  GOOD_GOODSMANAGEMENT_VIRTUALCODE = 'good-goodsManagement-virtualCode',
  /** 商品管理-商品管理-生成核销码 */
  GOOD_GOODSMANAGEMENT_VIRTUALCODECREATE = 'good-goodsManagement-virtualCodeCreate',
  /** 商品管理-商品管理-追加核销码 */
  GOOD_GOODSMANAGEMENT_VIRTUALCODEAPPEND = 'good-goodsManagement-virtualCodeAppend',
  /** 商品管理-商品管理-作废核销码 */
  GOOD_GOODSMANAGEMENT_VIRTUALCODECANCEL = 'good-goodsManagement-virtualCodeCancel',
  /** 商品管理-商品管理-删除核销码 */
  GOOD_GOODSMANAGEMENT_VIRTUALCODEDELETE = 'good-goodsManagement-virtualCodeDelete',
  /** 商品管理-商品管理-卡密管理 */
  GOOD_GOODSMANAGEMENT_VIRTUALCARD = 'good-goodsManagement-virtualCard',
  /** 商品管理-商品管理-追加卡密 */
  GOOD_GOODSMANAGEMENT_VIRTUALCARDAPPEND = 'good-goodsManagement-virtualCardAppend',
  /** 商品管理-商品管理-作废卡密 */
  GOOD_GOODSMANAGEMENT_VIRTUALCARDCANCEL = 'good-goodsManagement-virtualCardCancel',
  /** 商品管理-商品管理-删除卡密 */
  GOOD_GOODSMANAGEMENT_VIRTUALCARDDELETE = 'good-goodsManagement-virtualCardDelete',

  /** 商品管理-商品组 */
  GOOD_GOODSGROUP = 'good-goodsGroup',
  /** 商品管理-商品组-预览 */
  GOOD_GOODSGROUP_QUERY = 'good-goodsGroup-query',
  /** 商品管理-商品组-新增 */
  GOOD_GOODSGROUP_ADD = 'good-goodsGroup-add',
  /** 商品管理-商品组-编辑 */
  GOOD_GOODSGROUP_UPDATE = 'good-goodsGroup-update',
  /** 商品管理-商品组-删除 */
  GOOD_GOODSGROUP_DELETE = 'good-goodsGroup-delete',
  /** 商品管理-商品组-状态切换 */
  GOOD_GOODSGROUP_SHOWSTATUS = 'good-goodsGroup-showStatus',
  /** 商品管理-商品组-排序 */
  GOOD_GOODSGROUP_SORT = 'good-goodsGroup-sort',

  /** 商品管理-商品子组 */
  GOOD_GOODSCHILDGROUP = 'good-goodsChildGroup',
  /** 商品管理-商品子组-预览 */
  GOOD_GOODSCHILDGROUP_QUERY = 'good-goodsChildGroup-query',
  /** 商品管理-商品子组-新增 */
  GOOD_GOODSCHILDGROUP_ADD = 'good-goodsChildGroup-add',
  /** 商品管理-商品子组-编辑 */
  GOOD_GOODSCHILDGROUP_UPDATE = 'good-goodsChildGroup-update',
  /** 商品管理-商品子组-删除 */
  GOOD_GOODSCHILDGROUP_DELETE = 'good-goodsChildGroup-delete',
  /** 商品管理-商品子组-状态切换 */
  GOOD_GOODSCHILDGROUP_SHOWSTATUS = 'good-goodsChildGroup-showStatus',

  /** 商品管理-标签管理 */
  GOOD_TAGS = 'good-tags',
  /** 商品管理-标签管理-预览 */
  GOOD_TAGS_QUERY = 'good-tags-query',
  /** 商品管理-标签管理-新增 */
  GOOD_TAGS_ADD = 'good-tags-add',
  /** 商品管理-标签管理-编辑 */
  GOOD_TAGS_UPDATE = 'good-tags-update',
  /** 商品管理-标签管理-删除 */
  GOOD_TAGS_DELETE = 'good-tags-delete',
  /** 商品管理-标签管理-设置商品 */
  GOOD_TAGS_GOODSSETTING = 'good-tags-goodsSetting',

  /** 商品管理-海报管理 */
  GOOD_POSTER = 'good-poster',
  /** 商品管理-海报管理-预览 */
  GOOD_POSTER_QUERY = 'good-poster-query',
  /** 商品管理-海报管理-编辑 */
  GOOD_POSTER_UPDATE = 'good-poster-update',

  /** 商品管理-评价管理 */
  GOOD_COMMENTS = 'good-comments',
  /** 商品管理-评价管理-预览 */
  GOOD_COMMENTS_QUERY = 'good-comments-query',
  /** 商品管理-评价管理-回复评论 */
  GOOD_COMMENTS_REPLY = 'good-comments-reply',
  /** 商品管理-评价管理-回复追评 */
  GOOD_COMMENTS_REPLAYADDITIONAL = 'good-comments-replayAdditional',
  /** 商品管理-评价管理-评论导出 */
  GOOD_COMMENTS_EXPORT = 'good-comments-export',

  /** 财务管理 */
  FINANCE = 'finance',
  /** 财务管理-店铺账户 */
  FINANCE_SHOPACCOUNT = 'finance-shopAccount',
  /** 财务管理-店铺账户-预览 */
  FINANCE_SHOPACCOUNT_QUERY = 'finance-shopAccount-query',
  /** 财务管理-店铺账户-导出 */
  FINANCE_SHOPACCOUNT_EXPORT = 'finance-shopAccount-export',
  /** 财务管理-店铺账户-订单详情 */
  FINANCE_SHOPACCOUNT_ORDERDETAIL = 'finance-shopAccount-orderDetail',
  /** 财务管理-店铺账户-售后详情 */
  FINANCE_SHOPACCOUNT_AFTERSALEDETAIL = 'finance-shopAccount-afterSaleDetail',

  /** 财务管理-店铺营销账户 */
  FINANCE_SHOPMARKETINGACCOUNT = 'finance-shopMarketingAccount',
  /** 财务管理-店铺营销账户-预览 */
  FINANCE_SHOPMARKETINGACCOUNT_QUERY = 'finance-shopMarketingAccount-query',
  /** 财务管理-店铺营销账户-导出 */
  FINANCE_SHOPMARKETINGACCOUNT_EXPORT = 'finance-shopMarketingAccount-export',
  /** 财务管理-店铺营销账户-订单详情 */
  FINANCE_SHOPMARKETINGACCOUNT_ORDERDETAIL = 'finance-shopMarketingAccount-orderDetail',

  /** 财务管理-店铺保证金账户 */
  FINANCE_SHOPDEPOSITACCOUNT = 'finance-shopDepositAccount',
  /** 财务管理-店铺保证金账户-预览 */
  FINANCE_SHOPDEPOSITACCOUNT_QUERY = 'finance-shopDepositAccount-query',
  /** 财务管理-店铺保证金账户-导出 */
  FINANCE_SHOPDEPOSITACCOUNT_EXPORT = 'finance-shopDepositAccount-export',
  /** 财务管理-店铺保证金账户-订单详情 */
  FINANCE_SHOPDEPOSITACCOUNT_ORDERDETAIL = 'finance-shopDepositAccount-orderDetail',

  /** 财务管理-应收订单 */
  FINANCE_RECEIVABLEORDER = 'finance-receivableOrder',
  /** 财务管理-应收订单-预览 */
  FINANCE_RECEIVABLEORDER_QUERY = 'finance-receivableOrder-query',
  /** 财务管理-应收订单-导出 */
  FINANCE_RECEIVABLEORDER_EXPORT = 'finance-receivableOrder-export',
  /** 财务管理-应收订单-订单详情 */
  FINANCE_RECEIVABLEORDER_ORDERDETAIL = 'finance-receivableOrder-orderDetail',

  /** 财务管理-所有实收订单 */
  FINANCE_RECEIVEDORDER = 'finance-receivedOrder',
  /** 财务管理-所有实收订单-预览 */
  FINANCE_RECEIVEDORDER_QUERY = 'finance-receivedOrder-query',
  /** 财务管理-所有实收订单-导出 */
  FINANCE_RECEIVEDORDER_EXPORT = 'finance-receivedOrder-export',
  /** 财务管理-所有实收订单-订单详情 */
  FINANCE_RECEIVEDORDER_ORDERDETAIL = 'finance-receivedOrder-orderDetail',

  /** 财务管理-所有退款订单 */
  FINANCE_REFUNDORDER = 'finance-refundOrder',
  /** 财务管理-所有退款订单-预览 */
  FINANCE_REFUNDORDER_QUERY = 'finance-refundOrder-query',
  /** 财务管理-所有退款订单-导出 */
  FINANCE_REFUNDORDER_EXPORT = 'finance-refundOrder-export',
  /** 财务管理-所有退款订单-订单详情 */
  FINANCE_REFUNDORDER_ORDERDETAIL = 'finance-refundOrder-orderDetail',
  /** 财务管理-所有退款订单-售后详情 */
  FINANCE_REFUNDORDER_AFTERSALEDETAIL = 'finance-refundOrder-afterSaleDetail',
  /** 财务管理-所有退款订单-仲裁详情 */
  FINANCE_REFUNDORDER_ARBITRATIONDETAIL = 'finance-refundOrder-arbitrationDetail',
  /** 财务管理-所有退款订单-手动罚保证金 */
  FINANCE_REFUNDORDER_MARGIN = 'finance-refundOrder-margin',
  /** 财务管理-所有退款订单-手动退款 */
  FINANCE_REFUNDORDER_RETURN = 'finance-refundOrder-return',

  /** 财务管理-对账单 */
  FINANCE_STATEMENTOFACCOUNT = 'finance-statementOfAccount',
  /** 财务管理-对账单-预览 */
  FINANCE_STATEMENTOFACCOUNT_QUERY = 'finance-statementOfAccount-query',

  /** 财务管理-所有平台补贴 */
  FINANCE_PLATFORMSUBSIDY = 'finance-platformSubsidy',
  /** 财务管理-所有平台补贴-预览 */
  FINANCE_PLATFORMSUBSIDY_QUERY = 'finance-platformSubsidy-query',
  /** 财务管理-所有平台补贴-导出 */
  FINANCE_PLATFORMSUBSIDY_EXPORT = 'finance-platformSubsidy-export',
  /** 财务管理-所有平台补贴-订单详情 */
  FINANCE_PLATFORMSUBSIDY_ORDERDETAIL = 'finance-platformSubsidy-orderDetail',

  /** 财务管理-发票管理 */
  FINANCE_INVOICEMANAGEMENT = 'finance-invoiceManagement',
  /** 财务管理-发票管理-预览 */
  FINANCE_INVOICEMANAGEMENT_QUERY = 'finance-invoiceManagement-query',
  /** 财务管理-发票管理-导出 */
  FINANCE_INVOICEMANAGEMENT_EXPORT = 'finance-invoiceManagement-export',
  /** 财务管理-发票管理-详情 */
  FINANCE_INVOICEMANAGEMENT_DETAIL = 'finance-invoiceManagement-detail',
  /** 财务管理-发票管理-提交发票 */
  FINANCE_INVOICEMANAGEMENT_SUBMIT = 'finance-invoiceManagement-submit',

  /** 核销管理 */
  WRITEOFF = 'writeOff',
  /** 核销管理-核销组管理 */
  WRITEOFF_GROUP = 'writeOff-group',
  /** 核销管理-核销组管理-预览 */
  WRITEOFF_GROUP_QUERY = 'writeOff-group-query',
  /** 核销管理-核销组管理-新增 */
  WRITEOFF_GROUP_ADD = 'writeOff-group-add',
  /** 核销管理-核销组管理-编辑 */
  WRITEOFF_GROUP_UPDATE = 'writeOff-group-update',
  /** 核销管理-核销组管理-删除 */
  WRITEOFF_GROUP_DELETE = 'writeOff-group-delete',

  /** 核销管理-核销员工白名单 */
  WRITEOFF_WHITELIST = 'writeOff-whiteList',
  /** 核销管理-核销员工白名单-预览 */
  WRITEOFF_WHITELIST_QUERY = 'writeOff-whiteList-query',
  /** 核销管理-核销员工白名单-新增 */
  WRITEOFF_WHITELIST_ADD = 'writeOff-whiteList-add',
  /** 核销管理-核销员工白名单-删除 */
  WRITEOFF_WHITELIST_DELETE = 'writeOff-whiteList-delete',

  /** 优惠券管理 */
  COUPONMANAGEMENT = 'couponManagement',
  /** 优惠券管理-优惠券模版管理 */
  COUPONMANAGEMENT_TEMPLATE = 'couponManagement-template',
  /** 优惠券管理-优惠券模版管理-预览 */
  COUPONMANAGEMENT_TEMPLATE_QUERY = 'couponManagement-template-query',
  /** 优惠券管理-优惠券模版管理-新增 */
  COUPONMANAGEMENT_TEMPLATE_ADD = 'couponManagement-template-add',
  /** 优惠券管理-优惠券模版管理-详情 */
  COUPONMANAGEMENT_TEMPLATE_DETAIL = 'couponManagement-template-detail',
  /** 优惠券管理-优惠券模版管理-作废 */
  COUPONMANAGEMENT_TEMPLATE_CANCELLATION = 'couponManagement-template-cancellation',
  /** 优惠券管理-优惠券模版管理-领取记录 */
  COUPONMANAGEMENT_TEMPLATE_RECORD = 'couponManagement-template-record',
  /** 优惠券管理-优惠券模版管理-开关切换 */
  COUPONMANAGEMENT_TEMPLATE_SWITCHAUDITEXEMPTION = 'couponManagement-template-switchAuditExemption',
  /** 优惠券管理-优惠券模版管理-关联百视 */
  COUPONMANAGEMENT_TEMPLATE_BTV = 'couponManagement-template-Btv',

  /** 营销管理 */
  MAINGRAPHWATERMARK = 'mainGraphWatermark',
  /** 营销管理-主图水印 */
  MAINGRAPHWATERMARK_TEMPLATE = 'mainGraphWatermark-template',
  /** 营销管理-主图水印-预览 */
  MAINGRAPHWATERMARK_TEMPLATE_QUERY = 'mainGraphWatermark-template-query',
  /** 营销管理-主图水印-新增 */
  MAINGRAPHWATERMARK_TEMPLATE_ADD = 'mainGraphWatermark-template-add',
  /** 营销管理-主图水印-编辑 */
  MAINGRAPHWATERMARK_TEMPLATE_UPDATE = 'mainGraphWatermark-template-update',
  /** 营销管理-主图水印-管理商品 */
  MAINGRAPHWATERMARK_TEMPLATE_SHOP = 'mainGraphWatermark-template-shop',
  /** 营销管理-主图水印-查看活动 */
  MAINGRAPHWATERMARK_TEMPLATE_DETAIL = 'mainGraphWatermark-template-detail',
  /** 营销管理-主图水印-删除 */
  MAINGRAPHWATERMARK_TEMPLATE_DELETE = 'mainGraphWatermark-template-delete',
  /** 营销管理-主图水印-开始 */
  MAINGRAPHWATERMARK_TEMPLATE_BEGIN = 'mainGraphWatermark-template-begin',
  /** 营销管理-主图水印-结束 */
  MAINGRAPHWATERMARK_TEMPLATE_END = 'mainGraphWatermark-template-end',

  /** 营销管理-拼团活动列表 */
  GROUPLIST_LIST = 'groupList-list',
  /** 营销管理-拼团活动列表-预览 */
  GROUPLIST_LIST_QUERY = 'groupList-list-query',
  /** 营销管理-拼团活动列表-导出 */
  GROUPLIST_LIST_EXPORT = 'groupList-list-export',
  /** 营销管理-拼团活动列表-新增 */
  GROUPLIST_LIST_ADD = 'groupList-list-add',
  /** 营销管理-拼团活动列表-开关切换 */
  GROUPLIST_LIST_SWITCHAUDITEXEMPTION = 'groupList-list-switchAuditExemption',
  /** 营销管理-拼团活动列表-编辑 */
  GROUPLIST_LIST_UPDATE = 'groupList-list-update',
  /** 营销管理-拼团活动列表-查看 */
  GROUPLIST_LIST_READONLY = 'groupList-list-readonly',
  /** 营销管理-拼团活动列表-删除 */
  GROUPLIST_LIST_DELETE = 'groupList-list-delete',

  /** 营销管理-拼团管理 */
  GROUP_TEMPLATE = 'group-template',
  /** 营销管理-拼团管理-预览 */
  GROUP_TEMPLATE_QUERY = 'group-template-query',
  /** 营销管理-拼团管理-导出 */
  GROUP_TEMPLATE_EXPORT = 'group-template-export',
  /** 营销管理-拼团管理-查看成员 */
  GROUP_TEMPLATE_READONLY = 'group-template-readonly',

  /** 营销管理-预售活动列表 */
  PRESELLLIST_LIST = 'presellList-list',
  /** 营销管理-预售活动列表-预览 */
  PRESELLLIST_LIST_QUERY = 'presellList-list-query',
  /** 营销管理-预售活动列表-导出 */
  PRESELLLIST_LIST_EXPORT = 'presellList-list-export',
  /** 营销管理-预售活动列表-新增 */
  PRESELLLIST_LIST_ADD = 'presellList-list-add',
  /** 营销管理-预售活动列表-开关切换 */
  PRESELLLIST_LIST_SWITCHAUDITEXEMPTION = 'presellList-list-switchAuditExemption',
  /** 营销管理-预售活动列表-编辑 */
  PRESELLLIST_LIST_UPDATE = 'presellList-list-update',
  /** 营销管理-预售活动列表-查看 */
  PRESELLLIST_LIST_READONLY = 'presellList-list-readonly',
  /** 营销管理-预售活动列表-删除 */
  PRESELLLIST_LIST_DELETE = 'presellList-list-delete',

  /** 营销管理-预售管理 */
  PRESELL_TEMPLATE = 'presell-template',
  /** 营销管理-预售管理-预览 */
  PRESELL_TEMPLATE_QUERY = 'presell-template-query',
  /** 营销管理-预售管理-导出 */
  PRESELL_TEMPLATE_EXPORT = 'presell-template-export',
  /** 营销管理-预售管理-退回定金 */
  PRESELL_TEMPLATE_READONLY = 'presell-template-readonly',

  /** 营销管理-大转盘 */
  LOTTERY_TURNTABLE = 'lottery-turntable',
  /** 营销管理-大转盘-预览 */
  LOTTERY_TURNTABLE_QUERY = 'lottery-turntable-query',
  /** 营销管理-大转盘-编辑 */
  LOTTERY_TURNTABLE_UPDATE = 'lottery-turntable-update',
  /** 营销管理-大转盘-新增 */
  LOTTERY_TURNTABLE_ADD = 'lottery-turntable-add',
  /** 营销管理-大转盘-查看 */
  LOTTERY_TURNTABLE_DETAIL = 'lottery-turntable-detail',
  /** 营销管理-大转盘-删除 */
  LOTTERY_TURNTABLE_DELETE = 'lottery-turntable-delete',
  /** 营销管理-大转盘-结束活动 */
  LOTTERY_TURNTABLE_CLOSE = 'lottery-turntable-close',
  /** 营销管理-大转盘-切换状态 */
  LOTTERY_TURNTABLE_SWITCHSTATUS = 'lottery-turntable-switchStatus',
  /** 营销管理-大转盘-参与用户 */
  LOTTERY_TURNTABLE_USERLIST = 'lottery-turntable-userList',
  /** 营销管理-大转盘-参与用户导出 */
  LOTTERY_TURNTABLE_USERLIST_EXPORT = 'lottery-turntable-userList-export',

  /** 营销管理-平台活动 */
  MARKETING_PLANETACTIVITY = 'marketing-planetActivity',
  /** 营销管理-平台活动-预览 */
  MARKETING_PLANETACTIVITY_QUERY = 'marketing-planetActivity-query',
  /** 营销管理-平台活动-导出 */
  MARKETING_PLANETACTIVITY_EXPORT = 'marketing-planetActivity-export',
  /** 营销管理-平台活动-查看活动 */
  MARKETING_PLANETACTIVITY_DETAIL = 'marketing-planetActivity-detail',
  /** 营销管理-平台活动-活动报名 */
  MARKETING_PLANETACTIVITY_APPLY = 'marketing-planetActivity-apply',
  /** 营销管理-平台活动-我的商品 */
  MARKETING_PLANETACTIVITY_APPLYAGAIN = 'marketing-planetActivity-applyAgain',
  /** 营销管理-平台活动-历史活动 */
  MARKETING_PLANETACTIVITY_HISTORY = 'marketing-planetActivity-history',

  /** 营销管理-限时抢购列表 */
  MARKETING_TIMEBUYSTORE = 'marketing-timeBuyStore',
  /** 营销管理-限时抢购列表-预览 */
  MARKETING_TIMEBUYSTORE_QUERY = 'marketing-timeBuyStore-query',
  /** 营销管理-限时抢购列表-新增 */
  MARKETING_TIMEBUYSTORE_ADD = 'marketing-timeBuyStore-add',
  /** 营销管理-限时抢购列表-编辑 */
  MARKETING_TIMEBUYSTORE_UPDATE = 'marketing-timeBuyStore-update',
  /** 营销管理-限时抢购列表-删除 */
  MARKETING_TIMEBUYSTORE_DELETE = 'marketing-timeBuyStore-delete',
  /** 营销管理-限时抢购列表-查看 */
  MARKETING_TIMEBUYSTORE_DETAIL = 'marketing-timeBuyStore-detail',
  /** 营销管理-限时抢购列表-导出 */
  MARKETING_TIMEBUYSTORE_EXPORT = 'marketing-timeBuyStore-export',
  /** 营销管理-限时抢购列表-切换状态 */
  MARKETING_TIMEBUYSTORE_SWITCHSTATUS = 'marketing-timeBuyStore-switchStatus',

  /** 营销管理-限时抢购管理 */
  MARKETING_TIMEBUYSTORERECORD = 'marketing-timeBuyStoreRecord',
  /** 营销管理-限时抢购管理-预览 */
  MARKETING_TIMEBUYSTORERECORD_QUERY = 'marketing-timeBuyStoreRecord-query',
  /** 营销管理-限时抢购管理-导出 */
  MARKETING_TIMEBUYSTORERECORD_EXPORT = 'marketing-timeBuyStoreRecord-export',

  /** 订单管理 */
  ORDER = 'order',
  /** 订单管理-订单列表 */
  ORDER_LIST = 'order-list',
  /** 订单管理-订单列表-预览 */
  ORDER_LIST_QUERY = 'order-list-query',
  /** 订单管理-订单列表-导出 */
  ORDER_LIST_EXPORT = 'order-list-export',
  /** 订单管理-订单列表-批量标记 */
  ORDER_LIST_BATCHMARK = 'order-list-batchMark',
  /** 订单管理-订单列表-发货 */
  ORDER_LIST_SHIPPING = 'order-list-shipping',
  /** 订单管理-订单列表-详情 */
  ORDER_LIST_DETAIL = 'order-list-detail',
  /** 订单管理-订单列表-详情修改物流 */
  ORDER_LIST_SHIPPINGUPDATE = 'order-list-shippingUpdate',

  /** 订单管理-虚拟商品订单 */
  ORDER_VIRTUALLIST = 'order-virtualList',
  /** 订单管理-虚拟商品订单-预览 */
  ORDER_VIRTUALLIST_QUERY = 'order-virtualList-query',
  /** 订单管理-虚拟商品订单-详情 */
  ORDER_VIRTUALLIST_DETAIL = 'order-virtualList-detail',
  /** 订单管理-虚拟商品订单-导出 */
  ORDER_VIRTUALLIST_EXPORT = 'order-virtualList-export',

  /** 订单管理-发货管理 */
  ORDER_SHIPPING = 'order-shipping',
  /** 订单管理-发货管理-预览 */
  ORDER_SHIPPING_QUERY = 'order-shipping-query',
  /** 订单管理-发货管理-导入发货 */
  ORDER_SHIPPING_IMPORT = 'order-shipping-import',
  /** 订单管理-发货管理-批量标记 */
  ORDER_SHIPPING_BATCHMARK = 'order-shipping-batchMark',
  /** 订单管理-发货管理-批量打印配送单 */
  ORDER_SHIPPING_BATCHPRINTSHIPPINGRECEIPTS = 'order-shipping-batchPrintShippingReceipts',
  /** 订单管理-发货管理-批量打印快递单 */
  ORDER_SHIPPING_BATCHPRINTEXPRESSRECEIPTS = 'order-shipping-batchPrintExpressReceipts',
  /** 订单管理-发货管理-批量发货 */
  ORDER_SHIPPING_BATCHSHIPPING = 'order-shipping-batchShipping',
  /** 订单管理-发货管理-订单详情 */
  ORDER_SHIPPING_ORDERDETAIL = 'order-shipping-orderDetail',
  /** 订单管理-发货管理-发货 */
  ORDER_SHIPPING_SHIPPING = 'order-shipping-shipping',

  /** 订单管理-售后管理 */
  ORDER_AFTERSALE = 'order-aftersale',
  /** 订单管理-售后管理-预览 */
  ORDER_AFTERSALE_QUERY = 'order-aftersale-query',
  /** 订单管理-售后管理-导出 */
  ORDER_AFTERSALE_EXPORT = 'order-aftersale-export',
  /** 订单管理-售后管理-售后详情 */
  ORDER_AFTERSALE_AFTERSALEDETAIL = 'order-aftersale-afterSaleDetail',
  /** 订单管理-售后管理-订单详情 */
  ORDER_AFTERSALE_ORDERDETAIL = 'order-aftersale-orderDetail',
  /** 订单管理-售后管理-同意 */
  ORDER_AFTERSALE_AGREE = 'order-aftersale-agree',
  /** 订单管理-售后管理-拒绝 */
  ORDER_AFTERSALE_DISAGREE = 'order-aftersale-disagree',
  /** 订单管理-售后管理-填写退货物流 */
  ORDER_AFTERSALE_EXPRESS = 'order-aftersale-express',
  /** 订单管理-售后管理-验货通过 */
  ORDER_AFTERSALE_EXPRESSAGREE = 'order-aftersale-expressAgree',
  /** 订单管理-售后管理-验货不通过 */
  ORDER_AFTERSALE_EXPRESSDISAGREE = 'order-aftersale-expressDisagree',
  /** 订单管理-售后管理-重新退款 */
  ORDER_AFTERSALE_RETRYREFUND = 'order-aftersale-retryRefund',
  /** 订单管理-售后管理-线下处理 */
  ORDER_AFTERSALE_OFFLINEREFUND = 'order-aftersale-offlineRefund',

  /** 订单管理-仲裁列表 */
  ORDER_ARBITRATION = 'order-arbitration',
  /** 订单管理-仲裁列表-预览 */
  ORDER_ARBITRATION_QUERY = 'order-arbitration-query',
  /** 订单管理-仲裁列表-导出 */
  ORDER_ARBITRATION_EXPORT = 'order-arbitration-export',
  /** 订单管理-仲裁列表-仲裁详情 */
  ORDER_ARBITRATION_ARBITRATIONDETAIL = 'order-arbitration-arbitrationDetail',
  /** 订单管理-仲裁列表-售后详情 */
  ORDER_ARBITRATION_AFTERSALEDETAIL = 'order-arbitration-afterSaleDetail',
  /** 订单管理-仲裁列表-订单详情 */
  ORDER_ARBITRATION_ORDERDETAIL = 'order-arbitration-orderDetail',
  /** 订单管理-仲裁列表-提交仲裁材料 */
  ORDER_ARBITRATION_SUBMIT = 'order-arbitration-submit',

  /** 店铺装修 */
  DECORATION = 'decoration',
  /** 店铺装修-装修页面管理 */
  DECORATION_LIST = 'decoration-list',
  /** 店铺装修-装修页面管理-预览 */
  DECORATION_LIST_QUERY = 'decoration-list-query',
  /** 店铺装修-装修页面管理-新增 */
  DECORATION_LIST_ADD = 'decoration-list-add',
  /** 店铺装修-装修页面管理-编辑 */
  DECORATION_LIST_UPDATE = 'decoration-list-update',
  /** 店铺装修-装修页面管理-删除 */
  DECORATION_LIST_DELETE = 'decoration-list-delete',
  /** 店铺装修-装修页面管理-复制 */
  DECORATION_LIST_COPY = 'decoration-list-copy',
  /** 店铺装修-装修页面管理-设为首页 */
  DECORATION_LIST_SETHOME = 'decoration-list-setHome',

  /** 店铺装修-活动导航 */
  DECORATION_ACTIVITYNAVIGATION = 'decoration-activityNavigation',
  /** 店铺装修-活动导航-预览 */
  DECORATION_ACTIVITYNAVIGATION_QUERY = 'decoration-activityNavigation-query',
  /** 店铺装修-活动导航-新增 */
  DECORATION_ACTIVITYNAVIGATION_ADD = 'decoration-activityNavigation-add',
  /** 店铺装修-活动导航-编辑 */
  DECORATION_ACTIVITYNAVIGATION_UPDATE = 'decoration-activityNavigation-update',
  /** 店铺装修-活动导航-删除 */
  DECORATION_ACTIVITYNAVIGATION_DELETE = 'decoration-activityNavigation-delete',

  /** 店铺装修-素材库 */
  DECORATION_MATERIALLIBRARY = 'decoration-materialLibrary',
  /** 店铺装修-素材库-预览 */
  DECORATION_MATERIALLIBRARY_QUERY = 'decoration-materialLibrary-query',
  /** 店铺装修-素材库-编辑 */
  DECORATION_MATERIALLIBRARY_UPDATE = 'decoration-materialLibrary-update',
  /** 店铺装修-素材库-下载 */
  DECORATION_MATERIALLIBRARY_DOWNLOAD = 'decoration-materialLibrary-download',
  /** 店铺装修-素材库-复制链接 */
  DECORATION_MATERIALLIBRARY_LINKCOPY = 'decoration-materialLibrary-linkCopy',
  /** 店铺装修-素材库-删除 */
  DECORATION_MATERIALLIBRARY_DELETE = 'decoration-materialLibrary-delete',
  /** 店铺装修-素材库-上传 */
  DECORATION_MATERIALLIBRARY_UPLOAD = 'decoration-materialLibrary-upload',
  /** 店铺装修-素材库-移动 */
  DECORATION_MATERIALLIBRARY_MOVE = 'decoration-materialLibrary-move',
  /** 店铺装修-素材库-添加子分组 */
  DECORATION_MATERIALLIBRARY_GROUPADD = 'decoration-materialLibrary-groupAdd',
  /** 店铺装修-素材库-重命名分组 */
  DECORATION_MATERIALLIBRARY_GROUPRENAME = 'decoration-materialLibrary-groupRename',
  /** 店铺装修-素材库-删除分组 */
  DECORATION_MATERIALLIBRARY_GROUPDELETE = 'decoration-materialLibrary-groupDelete',

  /** 店铺设置 */
  SHOPSETTING = 'shopSetting',
  /** 店铺设置-订单设置 */
  SHOPSETTING_ORDERSETTING = 'shopSetting-orderSetting',
  /** 店铺设置-订单设置-预览 */
  SHOPSETTING_ORDERSETTING_QUERY = 'shopSetting-orderSetting-query',
  /** 店铺设置-订单设置-编辑 */
  SHOPSETTING_ORDERSETTING_UPDATE = 'shopSetting-orderSetting-update',

  /** 店铺设置-配送设置 */
  SHOPSETTING_SHIPPINGSETTING = 'shopSetting-shippingSetting',
  /** 店铺设置-配送设置-预览 */
  SHOPSETTING_SHIPPINGSETTING_QUERY = 'shopSetting-shippingSetting-query',
  /** 店铺设置-配送设置-新增运费模板 */
  SHOPSETTING_SHIPPINGSETTING_SHIPPINGTEMPLATEADD = 'shopSetting-shippingSetting-shippingTemplateAdd',
  /** 店铺设置-配送设置-编辑运费模板 */
  SHOPSETTING_SHIPPINGSETTING_SHIPPINGTEMPLATEUPDATE = 'shopSetting-shippingSetting-shippingTemplateUpdate',
  /** 店铺设置-配送设置-删除运费模板 */
  SHOPSETTING_SHIPPINGSETTING_SHIPPINGTEMPLATEDELETE = 'shopSetting-shippingSetting-shippingTemplateDelete',
  /** 店铺设置-配送设置-复制运费模板 */
  SHOPSETTING_SHIPPINGSETTING_SHIPPINGTEMPLATECOPY = 'shopSetting-shippingSetting-shippingTemplateCopy',
  /** 店铺设置-配送设置-设为默认运费模板 */
  SHOPSETTING_SHIPPINGSETTING_SHIPPINGTEMPLATESETDEFAULT = 'shopSetting-shippingSetting-shippingTemplateSetDefault',
  /** 店铺设置-配送设置-新增物流 */
  SHOPSETTING_SHIPPINGSETTING_EXPRESSADD = 'shopSetting-shippingSetting-expressAdd',
  /** 店铺设置-配送设置-编辑物流 */
  SHOPSETTING_SHIPPINGSETTING_EXPRESSUPDATE = 'shopSetting-shippingSetting-expressUpdate',
  /** 店铺设置-配送设置-删除物流 */
  SHOPSETTING_SHIPPINGSETTING_EXPRESSDELETE = 'shopSetting-shippingSetting-expressDelete',
  /** 店铺设置-配送设置-物流运单 */
  SHOPSETTING_SHIPPINGSETTING_EXPRESSDETAIL = 'shopSetting-shippingSetting-expressDetail',
  /** 店铺设置-配送设置-配送设置编辑 */
  SHOPSETTING_SHIPPINGSETTING_DISTRIBUTIONUPDATE = 'shopSetting-shippingSetting-distributionUpdate',
  /** 店铺设置-配送设置-地址库新增 */
  SHOPSETTING_SHIPPINGSETTING_ADDRESSADD = 'shopSetting-shippingSetting-addressAdd',
  /** 店铺设置-配送设置-地址库编辑 */
  SHOPSETTING_SHIPPINGSETTING_ADDRESSUPDATE = 'shopSetting-shippingSetting-addressUpdate',
  /** 店铺设置-配送设置-地址库删除 */
  SHOPSETTING_SHIPPINGSETTING_ADDRESSDELETE = 'shopSetting-shippingSetting-addressDelete',
  /** 店铺设置-配送设置-地址库设置默认 */
  SHOPSETTING_SHIPPINGSETTING_ADDRESSSETDEFAULT = 'shopSetting-shippingSetting-addressSetDefault',

  /** 店铺设置-应用管理 */
  SETTING_CLIENT = 'setting-client',
  /** 店铺设置-应用管理-预览 */
  SETTING_CLIENT_QUERY = 'setting-client-query',
  /** 店铺设置-应用管理-新增 */
  SETTING_CLIENT_ADD = 'setting-client-add',
  /** 店铺设置-应用管理-编辑 */
  SETTING_CLIENT_UPDATE = 'setting-client-update',
  /** 店铺设置-应用管理-授权 */
  SETTING_CLIENT_AUTH = 'setting-client-auth',
  /** 店铺设置-应用管理-删除 */
  SETTING_CLIENT_DELETE = 'setting-client-delete',

  /** 客服管理 */
  SERVICER = 'servicer',
  /** 客服管理-小能客服 */
  SERVICER_CHAT = 'servicer-chat',
  /** 客服管理-小能客服-聊天 */
  SERVICER_CHAT_QUERY = 'servicer-chat-query',

  /** 数据仪表 */
  DATAANALYSIS = 'dataAnalysis',
  /** 数据仪表-基础数据统计 */
  DATAANALYSIS_DATABASICS = 'dataAnalysis-dataBasics',
  /** 数据仪表-基础数据统计-预览 */
  DATAANALYSIS_DATABASICS_QUERY = 'dataAnalysis-dataBasics-query',

  /** 消息通知 */
  INFORMATION = 'information',
  /** 消息通知-消息通知 */
  INFORMATION_BTN = 'information-btn',
  /** 消息通知-消息通知-查看全部 */
  INFORMATION_BTN_ALL = 'information-btn-all',

}