import { routeNames, TRoutes } from '~/routes/const'
import { EAuthCode } from '~/enums/EAuthCode'

export default [
  {
    path: routeNames.shopAccount,
    component: () => import('../../pages/financialManagement/shopAccount'),
    meta: {
      title: '店铺账户',
      code: EAuthCode.FINANCE_SHOPACCOUNT
    }
  },
  {
    path: routeNames.shopMarketingAccount,
    component: () => import('../../pages/financialManagement/shopMarketingAccount'),
    meta: {
      title: '店铺营销账户',
      code: EAuthCode.FINANCE_SHOPMARKETINGACCOUNT
    }
  },
  {
    path: routeNames.shopDepositAccount,
    component: () => import('../../pages/financialManagement/shopDepositAccount'),
    meta: {
      title: '店铺保证金账户',
      code: EAuthCode.FINANCE_SHOPDEPOSITACCOUNT
    }
  },
  {
    path: routeNames.accountsReceivableOrders,
    component: () => import('../../pages/financialManagement/accountsReceivableOrders'),
    meta: {
      title: '应收订单',
      code: EAuthCode.FINANCE_RECEIVABLEORDER
    }
  },
  {
    path: routeNames.paidOrder,
    component: () => import('../../pages/financialManagement/paidOrder'),
    meta: {
      title: '实收订单',
      code: EAuthCode.FINANCE_RECEIVEDORDER
    }
  },
  {
    path: routeNames.refundOrders,
    component: () => import('../../pages/financialManagement/refundOrders'),
    meta: {
      title: '退款订单',
      code: EAuthCode.FINANCE_REFUNDORDER
    }
  },
  {
    path: routeNames.platformSubsidies,
    component: () => import('../../pages/financialManagement/platformSubsidies'),
    meta: {
      title: '平台补贴',
      code: EAuthCode.FINANCE_PLATFORMSUBSIDY
    }
  },
  {
    path: routeNames.statementOfAccount,
    component: () => import('../../pages/financialManagement/statementOfAccount'),
    meta: {
      title: '对账单',
      code: EAuthCode.FINANCE_STATEMENTOFACCOUNT
    }
  },
  {
    path: routeNames.invoiceManagement,
    component: () => import('../../pages/financialManagement/invoiceManagement'),
    meta: {
      title: '发票管理',
      code: EAuthCode.FINANCE_INVOICEMANAGEMENT
    }
  },
  {
    path: routeNames.invoiceManagementDetail,
    component: () => import('../../pages/financialManagement/invoiceManagement/detail'),
    meta: {
      title: '发票详情',
      hideInMenu: true,
      code: EAuthCode.FINANCE_INVOICEMANAGEMENT_DETAIL
    }
  }
] as TRoutes[]
