import { useRef, useState } from 'react'

export function useStateRef<S>(defaultValue: S): [React.MutableRefObject<S>, (newState: S) => void] {
  const [state, setState] = useState<S>(defaultValue)
  const notifysRef = useRef(state)

  function setStateRef(newState: S) {
    notifysRef.current = newState
    setState(newState)
  }

  return [notifysRef, setStateRef]
}
