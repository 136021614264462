import { FC, memo, useEffect, useMemo, useState } from 'react'
import { Typography, Result, Badge, List, Avatar, Popover, Button, notification, Tag, Space } from 'antd'
import { BellOutlined, FileDoneOutlined, FileSearchOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { EventSourcePolyfill } from 'event-source-polyfill'
import styles from './index.module.less'
import { api } from '~/request'
import { JsonResultListMallNotifyVo, MallNotifyVo } from '~/request/data-contracts'
import { routeNames } from '~/routes/const'
import { apiUrl, isDevelopment } from '~/config'
import { useStateRef } from './hooks'
import useAuth from '~/hooks/useAuth'
interface IProps {}

const { Text, Link } = Typography

const Component: FC<IProps> = (props) => {
  const { authMap } = useAuth([
    /** 消息通知-消息通知-查看全部 */
    'INFORMATION_BTN_ALL'
  ])
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [notifys, setNotifys] = useStateRef<MallNotifyVo[]>([])

  async function refreshNotifys() {
    setLoading(true)
    setIsError(false)
    try {
      const { data } = await api['/admin/mallStore/notify/allMsg_GET']({}, { skipInterceptor: 'response' }).then(
        ({ data }) => data as JsonResultListMallNotifyVo
      )
      setNotifys(data || [])
      setLoading(false)
    } catch (err) {
      setIsError(true)
      setLoading(false)
    }
  }

  function readNotify(id?: number, isOpenUrl = true) {
    const index = notifys.current.findIndex((item) => item.id === id)
    if (index >= 0) {
      const item = notifys.current[index]
      if (item.id) {
        const newNotifys = notifys.current.slice(0)
        newNotifys.splice(index, 1, { ...item, read: true })
        setNotifys(newNotifys)
        api['/admin/mallStore/notify/read_GET']({ notifyId: item.id })
      }
      if (item.url && isOpenUrl) {
        window.open(item.url, '_blank')
      }
    }
  }

  function readNotifyAll() {
    api['/admin/mallStore/notify/read_GET']({})
    setNotifys(notifys.current.map((item) => ({ ...item, read: true })))
  }

  function closeNotify(id: number) {
    notification.close(String(id))
    readNotify(id)
  }

  useEffect(() => {
    if (isDevelopment) {
      return
    }
    const Authorization = window.localStorage.getItem('Authorization')
    if (!Authorization) {
      return
    }

    refreshNotifys()

    const source = new EventSourcePolyfill(`${apiUrl}/admin/event`, {
      headers: { Authorization }
    })

    source.addEventListener('message', function (event) {
      try {
        const content: MallNotifyVo = JSON.parse(event.data)
        notification.open({
          message: (
            <div className={styles.message}>
              <Tag color="success">新消息</Tag>
              {content.title}
            </div>
          ),
          description: <div className={styles.description}>{content.content}</div>,
          key: String(content.id),
          btn: content.url ? <Link onClick={() => closeNotify(content.id!)}>查看</Link> : undefined
        })

        setNotifys([content].concat(notifys.current))
      } catch (err) {
        console.error('EventSource JSON Parse Error: ', err)
      }
    })

    source.addEventListener('open', function (event) {
      // console.log('open', event)
    })

    source.addEventListener('error', function (event) {
      // console.log('error', event)
    })

    return () => source.close()
  }, [])

  const notifysCount = useMemo(() => {
    return notifys.current.filter((item) => !item.read).length
  }, [notifys.current])

  const listItemDropdown = useMemo(() => {
    if (isError) {
      return <Result className={styles.noticeList} status="error" subTitle="加载失败" extra={<Button onClick={refreshNotifys}>重试</Button>} />
    }
    return (
      <List
        loading={loading}
        className={styles.noticeList}
        rowKey="id"
        size="small"
        dataSource={notifys.current}
        header={
          <div className={styles.between}>
            <Typography.Text strong>消息通知({notifys.current.length})</Typography.Text>
            <Space>
              <Button
                type="link"
                size="small"
                icon={<FileSearchOutlined />}
                disabled={!authMap.INFORMATION_BTN_ALL}
                onClick={() => history.push(routeNames.notifyList)}
              >
                查看全部
              </Button>
              <Button type="link" size="small" icon={<FileDoneOutlined />} disabled={notifysCount === 0} onClick={readNotifyAll}>
                一键已读
              </Button>
            </Space>
          </div>
        }
        renderItem={(item) => (
          <List.Item
            className={classNames(styles.noticeItem)}
            extra={item.url && <Link onClick={() => readNotify(item.id!)}>查看</Link>}
            onClick={() => readNotify(item.id!, false)}
          >
            <List.Item.Meta
              className={item.read ? styles.read : ''}
              title={item.title}
              description={item.content}
              avatar={
                <Avatar className={item.read ? '' : styles.unread} size={32}>
                  {item.read ? '已读' : '未读'}
                </Avatar>
              }
            />
          </List.Item>
        )}
      />
    )
  }, [notifys.current, loading, isError])

  return (
    <Popover content={listItemDropdown} placement="bottomRight">
      <Badge count={notifysCount} size="small" offset={[2, -2]}>
        <BellOutlined />
        <Text>消息通知</Text>
      </Badge>
    </Popover>
  )
}

Component.defaultProps = {}
Component.displayName = 'NotifyPush'

const NotifyPush = memo(Component)
export default NotifyPush
