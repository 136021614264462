import { TRoutes } from '~/routes/const';
import { routeNames } from '../const';


/** 开发模板只在开发环境有 */
const routes: TRoutes[] = process.env.NODE_ENV === 'development' ? [
  {
    path: routeNames.eTable,
    component: () => import('~/modules/@wmeimob/backend-pro/src/pages/tableTemplate'),
    meta: { title: '表格模板' }
  },
  {
    path: routeNames.eForm,
    component: () => import('~/modules/@wmeimob/backend-pro/src/pages/formTemplate'),
    meta: { title: '表单模板' }
  },
  {
    path: '/template/echartsTemplates',
    component: () => import('~/modules/@wmeimob/backend-pro/src/pages/echartsTemplates'),
    meta: { title: '图表模板' }
  }
] : []

export default routes;