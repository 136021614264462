import { createBrowserHistory } from 'history';
import { generateRouteAndMenu, IRoute, routeNames } from './const';
import BasicLayout from '~/layouts/basicLayout';
import MMPNoAuth from '~/pages/403';
import Login from '../pages/login'
import ChangePassword from '../pages/independentStore/changePassword'

import _example from './modules/_example';
import businessManagement from './modules/businessManagement'
import financialManagement from './modules/financialManagement'
import writeOffManagement from './modules/writeOffManagement'
import couponManagement from './modules/couponManagement'
import decorationSetting from './modules/decorationSetting'
import marketingManage from './modules/marketingManage'
import orderManage from './modules/orderManagement'
import goodsManagement from './modules/goodsManagement'
import shopSetting from './modules/shopSetting'
import servicer from './modules/servicer'
import dataAnalysis from './modules/dataAnalysis'
import notifyList from './modules/notifyList'
import { AccountBookOutlined, AppstoreOutlined, AuditOutlined, BarChartOutlined, BgColorsOutlined, MessageOutlined, NotificationOutlined, ProfileOutlined, ShopOutlined, TagsOutlined, TeamOutlined, UnorderedListOutlined } from '@ant-design/icons';


export const history = createBrowserHistory();

export const routers: IRoute[] = [
  {
    path: routeNames.login,
    component: Login,
    meta: { title: '登录', sync: true, noLogin: true }
  },
  {
    path: routeNames.submitData,
    component: () => import('../pages/independentStore/submitData'),
    meta: { title: '提交资料' }
  },
  {
    path: routeNames.submitDataEcho,
    component: () => import('../pages/independentStore/submitDataEcho'),
    meta: { title: '重新提交资料' }
  },
  {
    path: routeNames.submitDataCheck,
    component: () => import('../pages/independentStore/submitDataCheck'),
    meta: { title: '查看进度' }
  },
  {
    path: routeNames.shopAuditProgress,
    component: () => import('../pages/businessManagement/shopAuditProgress'),
    meta: { title: '审核进度' }
  },
  {
    path: routeNames.agreement,
    component: () => import('../pages/independentStore/agreement'),
    meta: { title: '各类协议', noLogin: true }
  },
  {
    path: routeNames.changePassword,
    component: ChangePassword,
    meta: { title: '修改密码', sync: true }
  },
  {
    path: routeNames.home,
    component: () => import('../pages/home'),
    meta: { title: '首页', noLogin: true }
  },
  {
    path: routeNames.agreements,
    component: () => import('../pages/agreements'),
    meta: { title: '协议', noLogin: true }
  },
  {
    path: '/',
    layout: BasicLayout,
    redirect: '/home',
    children: [
      { name: '商家管理', icon: TeamOutlined, children: businessManagement },
      { name: '商品管理', icon: AppstoreOutlined, children: goodsManagement },
      { name: '财务管理', icon: AccountBookOutlined, children: financialManagement },
      { name: '核销管理', icon: AuditOutlined, children: writeOffManagement },
      { name: '优惠券管理', icon: TagsOutlined, children: couponManagement },
      { name: '订单管理', icon: ProfileOutlined, children: orderManage },
      { name: '营销管理', icon: NotificationOutlined, children: marketingManage },
      { name: '店铺装修', icon: BgColorsOutlined, children: decorationSetting },
      { name: '店铺设置', icon: ShopOutlined, children: shopSetting },
      // { name: '客服管理', icon: MessageOutlined, children: servicer },
      { name: '数据仪表', icon: BarChartOutlined, children: dataAnalysis },
      { name: '消息通知', icon: BarChartOutlined, children: notifyList },
      { name: '模板', path: '/_example', icon: UnorderedListOutlined, children: _example },
      { path: routeNames[403], component: MMPNoAuth, meta: { sync: true, noLogin: true } }
    ]
  }
]

const { routes } = generateRouteAndMenu(routers)

export function getMenus() {
  return generateRouteAndMenu(routers).menus;
}

export { routes }
